<template>
  <div class="js-common-wrap">
    <div class="js-common-head">
      <label>已完成任务列表</label>
      <div>
        <el-button type="primary" size="small" @click="search" v-if="getTempraryShow">高级搜索</el-button>
      </div>
    </div>

    <div class="js-common-content">
      <el-table class="js-table" :data="tableData" height="100%" empty-text="暂无数据" @row-click="handleRowClick">
        <el-table-column prop="name" label="任务名称"></el-table-column>
        <el-table-column prop="subtaskCount" label="子任务个数"></el-table-column>
        <el-table-column prop="eventCount" label="事件个数" ></el-table-column>
        <el-table-column prop="executorCount" label="执行人人数"></el-table-column>
        <el-table-column prop="closeTime" label="关闭时间"></el-table-column>
        <el-table-column prop="distributionTime" label="分发时间"></el-table-column>
        <!--
        <el-table-column label="查看详细报告">
          <template slot-scope="scope">
            <i class="el-icon-s-operation" @click.stop="findDetails(scope.row)"></i>
          </template>
        </el-table-column>
        -->
      </el-table>
    </div>
    <common-pagination v-model="pageOptions" @curPageChange="handleCurPageChange"></common-pagination>
    <task-record-search v-model="searchDialogVisible" @handleSearch="handleSearch"></task-record-search>
  </div>
</template>
<script>
import CommonPagination from '@/components/CommonPagination'
import TaskRecordSearch from '@/components/TaskRecordSearch'
import { mapGetters } from "vuex";

export default {
  name: '',
  components: {
    CommonPagination,
    TaskRecordSearch
  },
  data() {
    return {
      tableData: [],
      editDialogVisible: false,
      pageOptions:{
        page: 1,
        perPage: 20,
        total: 0
      },
      options:{
        name:'',
        distributionStartDate:'',
        distributionEndDate:''
      },
      type: false,
      searchDialogVisible:false,
    }
  },
  mounted() {
    this.getList()
  },
  computed: {
    ...mapGetters(["getTempraryShow"]),
  },
  methods: {

    search(){
      this.searchDialogVisible = true
    },

//search
    handleSearch(params){
      this.pageOptions.page = 1,
      this.pageOptions.perPage = 20,
      this.options.name = params.name,
      this.options.distributionStartDate = params.distributionStartDate,
      this.optins.distributionEndDate = params.distributionEndDate
    },


    getList(){
      this.$axios({
        method: 'get',
        url: 'api/csp/task/v1/task/records/',
        params: {
          page: this.pageOptions.page,
          perPage: this.pageOptions.perPage,
          name: this.options.name,
          distributionStartDate: this.options.distributionStartDate,
          distributionEndDate: this.options.distributionEndDate
        }
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.tableData = data.data.rows
          this.pageOptions.total = data.data.total
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },

//jump to detail page
    handleRowClick(row){
      // let flag = !row.exeCount && !row.status
      this.$router.push({
        path:`/record/taskRecordDetail`,
        query:{
          id:row.id
        }
      })
    },

    // 分页
    handleCurPageChange(){
      this.getList()
    },

    //edit
    handleEdit(flag,row){
      let options = ''
      if(flag){
        options = row
        this.type = 1
      }else{
        options = {
          attachments: '',
          content: '',
          gmtCreated: '',
          gmtEnd: '',
          id: '',
          interruptFlag: '',
          name: '',
          recordingType: '',
          remark: '',
        }
        this.type = 0
      }
      this.$router.push({
        path: `/record/taskRecordDetail`,
        query:{
          options: JSON.stringify(options),
          type: this.type
        }
      })
    }
  },
}
</script>
<style lang="scss" scoped>
</style>
