var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "js-common-wrap" },
    [
      _c("div", { staticClass: "js-common-head" }, [
        _c("label", [_vm._v("已完成任务列表")]),
        _c(
          "div",
          [
            _vm.getTempraryShow
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.search },
                  },
                  [_vm._v("高级搜索")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "js-common-content" },
        [
          _c(
            "el-table",
            {
              staticClass: "js-table",
              attrs: {
                data: _vm.tableData,
                height: "100%",
                "empty-text": "暂无数据",
              },
              on: { "row-click": _vm.handleRowClick },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "任务名称" },
              }),
              _c("el-table-column", {
                attrs: { prop: "subtaskCount", label: "子任务个数" },
              }),
              _c("el-table-column", {
                attrs: { prop: "eventCount", label: "事件个数" },
              }),
              _c("el-table-column", {
                attrs: { prop: "executorCount", label: "执行人人数" },
              }),
              _c("el-table-column", {
                attrs: { prop: "closeTime", label: "关闭时间" },
              }),
              _c("el-table-column", {
                attrs: { prop: "distributionTime", label: "分发时间" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("common-pagination", {
        on: { curPageChange: _vm.handleCurPageChange },
        model: {
          value: _vm.pageOptions,
          callback: function ($$v) {
            _vm.pageOptions = $$v
          },
          expression: "pageOptions",
        },
      }),
      _c("task-record-search", {
        on: { handleSearch: _vm.handleSearch },
        model: {
          value: _vm.searchDialogVisible,
          callback: function ($$v) {
            _vm.searchDialogVisible = $$v
          },
          expression: "searchDialogVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }