<template>
  <el-dialog class="js-dialog-form" title="高级搜索" :visible.sync="dialogVisible" :before-close="handleCancel" :close-on-click-modal="false" width="560px">
    <ul>
      <!-- <li class="label">名称</li> -->
      <li>
        <div class="label common-tip"><i>*</i>名称</div>
        <el-input v-model="name" placeholder="请输入名称" size="small"></el-input>
      </li>
      <li>
        <div class="label common-tip">
          <i></i>开始时间:                                                              
        </div>
        <el-date-picker
          v-model="distributionStartDate"
          type="datetime"
          placeholder="选择开始日期时间 （派发时间）"
          value-format= "yyyy-MM-dd HH:mm:ss">
        </el-date-picker>
      </li>
      <li>
        <div class="label common-tip">
          <i></i>结束时间:                                                              
        </div>
        <el-date-picker
          v-model="distributionEndDate"
          type="datetime"
          placeholder="选择结束日期时间 （派发时间）"
          value-format= "yyyy-MM-dd HH:mm:ss">
        </el-date-picker>
      </li>
    </ul>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel" size="small">取 消</el-button>
      <el-button type="primary" @click="handleConfirm" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'Jiashi10taskrecordsearch',
  props: ['value'],
  data() {
    return {
      name: '',
      distributionStartDate: '',
      distributionEndDate:'',
    }
  },
  computed: {
    dialogVisible: {
      get(){
        return this.value
      },
      set(newVal){
        this.$emit('input', newVal)
      }
    }
  },
  mounted() {
  },
  methods: {
    handleCancel(){
      this.name = ''
      this.distributionStartDate=''
      this.distributionEndDate = ''
      this.dialogVisible = false
    },
    handleConfirm(){
      let params = {
        name: this.name,
        distributionStartDate: this.distributionStartDate,
        distributionEndDate: this.distributionEndDate
      }
      this.$emit('handleSearch',params)
    }
  },
};
</script>

<style lang="scss" scoped>

</style>